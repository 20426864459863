import axios from "axios";

const url = "https://api.merollabergueda.com/api/";

const Login = async (email, password) => {
    let message;
    await axios
        .post(url + "login", {
            email: email,
            password: password,
            remember_me: true,
        })
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("user", JSON.stringify(response.data));
                message = "Login amb èxit";
            }
            if (
                response.data.status === "failed" &&
                response.data.success === undefined
            ) {
                message = "error";
            }
        })
        .catch((error) => {
            message = "error";
            console.log(error);
        });

    return message;
};

const getUser = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "user", config)
        .then((response) => {
            if (response.status === 200) {
                message = response.status;
            }
            if (
                response.data.status === "failed" &&
                response.data.success === undefined
            ) {
                message = "401";
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
            }
        });
    return { message };
};

const logout = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let get_user;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "logout", config)
        .then((response) => {
            if (response.status === 200) {
                get_user = response.data;
                message = "400";
            }
            if (
                response.data.status === "failed" &&
                response.data.success === undefined
            ) {
                message = "401";
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                localStorage.setItem("isLoggedIn", false);
            }
        });
    return { get_user, message };
};

const getElements = async (element) => {
    let message;
    let result;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios
        .get(url + element, config)
        .then((response) => {
            if (response.status === 200) {
                result = response.data.data;
                message = "Obtingut amb èxit";
            }
            if (
                response.data.status === "failed" &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { result, message };
};

const getElement = async (element, key) => {
    let message;
    let result;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios
        .get(url + element + "/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                result = response.data.data;
                message = "Obtingut amb èxit";
            }
            if (
                response.data.status === "failed" &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { result, message };
};

const createActivitat = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const data = new FormData();

    data.append("nom", values.nom);
    data.append("nom_es", values.nom_es);
    data.append("descripcio", values.descripcio);
    data.append("descripcio_es", values.descripcio_es);
    data.append("preu_adult", values.preu_adult ? values.preu_adult : "");
    data.append("preu_4", values.preu_4 ? values.preu_4 : "");
    data.append("preu_nen", values.preu_nen ? values.preu_nen : "");
    data.append("duracio", values.duracio ? values.duracio : "");
    data.append("text", values.text);
    data.append("text_es", values.text_es);
    if (values.foto[0].name) {
        data.append("foto", values.foto[0]);
    }

    let message;

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };

    await axios.post(url + "activitats", data, config).then((response) => {
        if (response.status === 200) {
            message = "Activitat creada";
        }
        if (
            response.data.status === "failed" &&
            response.data.success === undefined
        ) {
            message = "Error al crear";
        }
    });

    return message;
};

const updateActivitat = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const data = new FormData();

    data.append("nom", values.nom);
    data.append("nom_es", values.nom_es);
    data.append("descripcio", values.descripcio);
    data.append("descripcio_es", values.descripcio_es);
    data.append("preu_adult", values.preu_adult ? values.preu_adult : "");
    data.append("preu_4", values.preu_4 ? values.preu_4 : "");
    data.append("preu_nen", values.preu_nen ? values.preu_nen : "");
    data.append("duracio", values.duracio);
    data.append("text", values.text);
    data.append("text_es", values.text_es);
    if (values.foto[0].name) {
        data.append("foto", values.foto[0]);
    }

    let message;

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };

    await axios
        .post(url + "activitats/" + key, data, config)
        .then((response) => {
            if (response.status === 200) {
                message = "Activitat actualitzada";
            }
            if (
                response.data.status === "failed" &&
                response.data.success === undefined
            ) {
                message = "Error al actualitzar";
            }
        });

    return message;
};

const deleteActivitat = async (key) => {
    const user = JSON.parse(localStorage.getItem("user"));

    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios.delete(url + "activitats/" + key, config).then((response) => {
        if (response.status === 200) {
            message = "Eliminat amb èxit";
        }
        if (
            response.data.status === "failed" &&
            response.data.success === undefined
        ) {
            message = "Error al eliminar";
        }
    });

    return message;
};

const createProtagonista = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const data = new FormData();

    data.append("nom", values.nom);
    data.append("descripcio", values.descripcio);
    data.append("descripcio_es", values.descripcio_es);
    data.append("link", values.link);
    if (values.foto[0].name) {
        data.append("foto", values.foto[0]);
    }

    let message;

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };

    await axios.post(url + "protagonistes", data, config).then((response) => {
        if (response.status === 200) {
            message = "Protagonista creada";
        }
        if (
            response.data.status === "failed" &&
            response.data.success === undefined
        ) {
            message = "Error al crear";
        }
    });

    return message;
};

const updateProtagonista = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const data = new FormData();

    data.append("nom", values.nom);
    data.append("descripcio", values.descripcio);
    data.append("descripcio_es", values.descripcio_es);
    data.append("link", values.link);
    if (values.foto[0].name) {
        data.append("foto", values.foto[0]);
    }
    let message;

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };

    await axios
        .post(url + "protagonistes/" + key, data, config)
        .then((response) => {
            if (response.status === 200) {
                message = "Protagonista actualitzada";
            }
            if (
                response.data.status === "failed" &&
                response.data.success === undefined
            ) {
                message = "Error al actualitzar";
            }
        });

    return message;
};

const deleteProtagonista = async (key) => {
    const user = JSON.parse(localStorage.getItem("user"));

    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios
        .delete(url + "protagonistes/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                message = "Eliminat amb èxit";
            }
            if (
                response.data.status === "failed" &&
                response.data.success === undefined
            ) {
                message = "Error al eliminar";
            }
        });

    return message;
};

const contacte = async (values) => {
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "contacte", values, config).then((response) => {
        if (response.status === 200) {
            message = "Missatge enviat!";
        }
        if (
            response.data.status === "failed" &&
            response.data.success === undefined
        ) {
            message = "Error al enviar missatge. Prova-ho més tard";
        }
    });

    return message;
};

export {
    Login,
    getUser,
    logout,
    getElements,
    getElement,
    createActivitat,
    updateActivitat,
    deleteActivitat,
    createProtagonista,
    updateProtagonista,
    deleteProtagonista,
    contacte,
};
