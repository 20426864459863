import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1150,
            xl: 1920,
        },
    },
    color: {
        primary: "#9F371F",
        secondary: "#E9B92D",
        third: "#1D1D1B",
        background: "#F4E9D7",
        background_dark: "#f1e0c2",
    },
    palette: {
        background: {
            main: "#FFF",
            secondary: "#9F371F",
            third: "#E9B92D",
        },
        primary: {
            main: "#1D1D1B",
            hover: "#1D1D1B",
        },
        secondary: {
            main: "#9F371F",
            hover: "#9F371F",
        },
        danger: {
            main: colors.red[500],
            light: colors.red[300],
        },
        success: {
            main: colors.green[500],
        },
        text: {
            primary: "#1D1D1B",
            secondary: "#9F371F",
            third: "#E9B92D",
        },
        typography: {
            fontFamily: "Azeret Mono",
        },
    },
    typography: {
        h1: {
            fontSize: "4.3rem",
            fontFamily: "Londrina Solid",
            color: "#9F371F",
            lineHeight: 0.9,
            [breakpoints.down("sm")]: {
                fontSize: "3.7rem",
            },
            textTransform: "uppercase",
        },
        h2: {
            fontSize: "4.3rem",
            fontFamily: "Londrina Solid",
            color: "#1D1D1B",
            lineHeight: 0.9,
            [breakpoints.down("sm")]: {
                fontSize: "3rem",
            },
            textTransform: "uppercase",
        },
        h3: {
            fontSize: "3.5rem",
            fontFamily: "Londrina Solid",
            textTransform: "uppercase",
            color: "#1D1D1B",
            [breakpoints.down("sm")]: {
                fontSize: "2.8rem",
            },
        },
        h4: {
            fontSize: "3.5rem",
            fontFamily: "Londrina Solid",
            textTransform: "uppercase",
            color: "#9F371F",
            [breakpoints.down("sm")]: {
                fontSize: "2.8rem",
            },
        },
        h5: {
            fontSize: "1.5rem",
            fontFamily: "Londrina Solid",
            fontWeight: 800,
            color: "#1D1D1B",
        },
        h6: {
            fontSize: "0.9rem",
            fontFamily: "Londrina Solid",
            fontWeight: 500,
            color: "#1D1D1B",
            textTransform: "uppercase",
        },
        body1: {
            fontSize: "0.9rem",
            fontFamily: "Azeret Mono",
            color: "#1D1D1B",
            paddingTop: 5,
            paddingBottom: 5,
        },
        body2: {
            fontFamily: "Azeret Mono",
            fontSize: "0.8rem",
            color: "#1D1D1B",
        },
        caption: {
            fontFamily: "Azeret Mono",
            color: "#1D1D1B",
        },
        root: {
            "& a": {
                color: "#1D1D1B",
                textDecoration: "none",
                transition: "0.5s",
                "&:hover": {
                    color: "black",
                },
            },
        },
        error: {
            fontFamily: "Azeret Mono",
            color: "#d32f2f",
            fontSize: "0.75rem",
        },
        title: {
            fontFamily: "Azeret Mono",
            fontSize: "3.5rem",
            color: "#1D1D1B",
        },
        link: {
            fontSize: "1.3rem",
            fontFamily: "Londrina Solid",
            fontWeight: 500,
            color: "#1D1D1B",
        },
        footer: {
            fontSize: "0.8rem",
            fontFamily: "Azeret Mono",
            color: "#F4E9D7",
        },
    },
    "& a": {
        color: "black",
    },
});

export default theme;
