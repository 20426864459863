import { Instagram, Link } from "@mui/icons-material";
import {
    Button,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import img from "../../../../assets/fons/background_clar.jpg";
import svg from "../../../../assets/simbols/mascara-activitats-1.svg";
import svg2 from "../../../../assets/simbols/mascara-activitats-2.svg";
import { ReactComponent as Simbol1 } from "../../../../assets/simbols/simbol3.svg";
import { ReactComponent as Simbol2 } from "../../../../assets/simbols/simbol2.svg";
import { ReactComponent as Simbol3 } from "../../../../assets/simbols/simbol1.svg";
import { useTranslation } from "react-i18next";

const ProtagonistaElement = ({ protagonista, index }) => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");
    const { t, i18n } = useTranslation();

    const sanefa = () => {
        switch (index % 3) {
            case 0:
                return <Simbol1 className={classes.simbol1} />;

            case 1:
                return <Simbol2 className={classes.simbol2} />;

            case 2:
                return <Simbol3 className={classes.simbol3} />;

            default:
                break;
        }
    };

    return (
        <Grid container spacing={3} className={classes.main}>
            <Grid item md={3} xs={12} className={classes.imgWrap}>
                <img
                    src={
                        "https://api.merollabergueda.com/public/storage/" +
                        protagonista.foto
                    }
                    alt={protagonista.nom}
                    className={index % 2 === 0 ? classes.img : classes.img2}
                />
                {sanefa()}
            </Grid>
            <Grid item md={6} xs={12}>
                <Typography variant="h3" pr={2}>
                    {protagonista.nom}
                </Typography>
                <Typography>
                    {i18n.language === "ca"
                        ? protagonista.descripcio
                        : protagonista.descripcio_es}
                </Typography>
            </Grid>
            <Grid item md={3} xs={12} mt={matches ? 0 : 4}>
                <Button
                    onClick={() => window.open(protagonista.link)}
                    variant="contained"
                    fullWidth
                >
                    <Typography color="white" mr={1}>
                        {t("video")}
                    </Typography>
                    <Link />
                </Button>
            </Grid>
        </Grid>
    );
};

export default ProtagonistaElement;

const useStyles = makeStyles((theme) => ({
    main: {
        padding: 30,
        background: `url(${img})`,
        marginBottom: 80,
        justifyContent: "space-between",
        borderRadius: 30,
    },
    simbol1: {
        position: "absolute",
        top: 60,
        left: 20,
        width: 60,
        fill: theme.color.secondary,
        transform: "scaleX(-1)",
    },
    simbol2: {
        position: "absolute",
        bottom: 20,
        left: 20,
        width: 60,
        fill: theme.color.primary,
    },
    simbol3: {
        position: "absolute",
        bottom: 10,
        left: -15,
        width: 110,
        fill: theme.color.secondary,
        transform: "rotate(20deg)",
    },
    imgWrap: {
        width: 250,
        height: 264,
        zIndex: 0,
        position: "relative",
        mask: `url(${svg})`,
        maskSize: "cover",
        maskRepeat: "no-repeat",
        maskPosition: "center",
        [theme.breakpoints.down("sm")]: {
            width: 250,
            height: 304,
        },
    },
    img: {
        width: "100%",
        height: "100%",
        zIndex: 0,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        objectFit: "cover",
    },
    img2: {
        width: "100%",
        height: "100%",
        zIndex: 0,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        objectFit: "cover",
    },
}));
