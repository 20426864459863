import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    wrap: {
        // borderBottom: "1px solid " + theme.palette.background.light,
        padding: 8,
        // backgroundColor: "white",
        borderRadius: 10,
    },
    main: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const Title = ({ title, button }) => {
    const classes = useStyles();

    return (
        <Container maxWidth="xl" className={classes.wrap}>
            <Box className={classes.main}>
                <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textTransform: "uppercase" }}
                >
                    {title}
                </Typography>
                {button}
            </Box>
        </Container>
    );
};

export default Title;
