import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import svg from "../../../assets/simbols/mascara-activitats-1.svg";
import svg2 from "../../../assets/simbols/mascara-activitats-2.svg";
import { ReactComponent as Simbol1 } from "../../../assets/simbols/simbol3.svg";
import { WhatsApp } from "@mui/icons-material";
import Page from "../../../components/layouts/Page";
import { useLocation } from "react-router";
import CustomButton from "../../../components/CustomButton";
import Header from "../../../components/layouts/Header";
import bg from "../../../assets/fons/background_fost.jpg";
import bg2 from "../../../assets/fons/background_clar.jpg";
import video from "../../../assets/video/passeja.MOV";
import { useTranslation } from "react-i18next";

const ActivitatSingle = () => {
    const classes = useStyles();
    const location = useLocation();
    const activitat = location.state;
    const { t, i18n } = useTranslation();
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <Page
            title={
                "Merolla de Berguedà - " + i18n.language === "ca"
                    ? activitat.nom
                    : activitat?.nom_es
            }
        >
            <Header
                title={
                    <Box display={matches ? "flex" : "block"}>
                        {" "}
                        <Typography
                            variant="h3"
                            fontSize={80}
                            className={classes.text}
                        >
                            {removeLastWord(
                                i18n.language === "ca"
                                    ? activitat.nom
                                    : activitat?.nom_es
                            )}
                        </Typography>
                        <Box mx={1} />
                        <Typography
                            variant="h4"
                            className={classes.text}
                            fontSize={80}
                        >
                            {lastWord(
                                i18n.language === "ca"
                                    ? activitat.nom
                                    : activitat?.nom_es
                            )}
                        </Typography>
                    </Box>
                }
            />
            <Box className={classes.main}>
                <Container>
                    <Grid spacing={3} container>
                        <Grid item md={6} mb={5}>
                            <Box>
                                <Typography
                                    variant="body1"
                                    className={classes.textDesc}
                                >
                                    {i18n.language === "ca"
                                        ? activitat.text
                                              .split("\n")
                                              .map(function (item) {
                                                  return item + "\n\n";
                                              })
                                        : activitat?.text_es
                                              ?.split("\n")
                                              .map(function (item) {
                                                  return item + "\n\n";
                                              })}
                                </Typography>
                                {activitat.duracio && (
                                    <Typography variant="body1">
                                        {t("duracio")}{" "}
                                        <strong>{activitat.duracio}</strong>
                                    </Typography>
                                )}
                                {activitat.preu_adult && (
                                    <Typography variant="body1">
                                        {t("preu_persona")}{" "}
                                        <strong>
                                            {activitat.preu_adult} €
                                        </strong>
                                    </Typography>
                                )}
                                {activitat.preu_nen && (
                                    <Typography variant="body1">
                                        {t("preu_nens")}{" "}
                                        <strong>{activitat.preu_nen} €</strong>
                                    </Typography>
                                )}
                                {activitat.preu_4 && (
                                    <Typography variant="body1">
                                        {t("preu_4")}{" "}
                                        <strong>{activitat.preu_4} €</strong>
                                    </Typography>
                                )}
                                <Box mt={3} />
                                <CustomButton
                                    onClick={() =>
                                        window.open(
                                            "https://wa.me/34649126476?text=Hola,%20hola!"
                                        )
                                    }
                                    title={
                                        <Box display="flex" alignItems="center">
                                            <WhatsApp />{" "}
                                            <span style={{ marginLeft: 5 }}>
                                                {t("contactam")}
                                            </span>
                                        </Box>
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} mb={5}>
                            <Box className={classes.imgWrap}>
                                <img
                                    src={
                                        "https://api.merollabergueda.com/public/storage/" +
                                        activitat.foto
                                    }
                                    alt={activitat.nom}
                                    className={classes.img}
                                />
                                {/* <Simbol1 className={classes.simbol1} /> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {activitat.id === 2 && (
                <Box className={classes.main2}>
                    <Container>
                        <video width="400" controls>
                            <source src={video} type="video/mp4" />
                        </video>
                    </Container>
                </Box>
            )}
        </Page>
    );
};

export default ActivitatSingle;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        paddingTop: 100,
        paddingBottom: 100,
    },
    main2: {
        background: `url(${bg2})`,
        paddingTop: 100,
        paddingBottom: 100,
        textAlign: "center",
    },
    img: {
        width: "100%",
        height: "100%",
        zIndex: 0,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        objectFit: "cover",
    },
    imgWrap: {
        width: 400,
        height: 400,
        zIndex: 0,
        position: "relative",
        mask: `url(${svg})`,
        maskSize: "cover",
        maskRepeat: "no-repeat",
        maskPosition: "center",
    },
    img2: {
        width: "100%",
        mask: `url(${svg2})`,

        maskSize: "contain",
        maskRepeat: "no-repeat",
        maskPosition: "center",
        zIndex: 0,
    },
    text: {
        zIndex: 1,
    },
    textDesc: {
        whiteSpace: "pre-line",
    },
    simbol1: {
        position: "absolute",
        top: 60,
        left: 0,
        width: 60,
        fill: theme.color.secondary,
        transform: "scaleX(-1)",
    },

    simbol2: {
        position: "absolute",
        bottom: 20,
        left: 0,
        width: 60,
        fill: theme.color.primary,
    },
}));

function removeLastWord(str) {
    const lastIndexOfSpace = str?.lastIndexOf(" ");

    if (lastIndexOfSpace === -1) {
        return str;
    }

    return str?.substring(0, lastIndexOfSpace);
}

function lastWord(str) {
    const lastIndexOfSpace = str?.lastIndexOf(" ");

    if (lastIndexOfSpace === -1) {
        return str;
    }

    return str?.substring(lastIndexOfSpace, str.length);
}
