import { Add } from "@mui/icons-material";
import { IconButton, ThemeProvider } from "@mui/material";
import { Box } from "@mui/system";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loading from "../../../components/layouts/Loading";
import Page from "../../../components/layouts/Page";
import Title from "../../../components/layouts/Title";
import { getElements } from "../../../database/API";
import getMuiTheme from "../../../theme/TableStyle";
import TableOptions from "../../../components/TableOptions";
import ProtagonistesColumns from "./table/ProtagonistesColumns";

const ProtagonistesAdmin = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [protagonistes, setProtagonistes] = useState([]);
    const columns = ProtagonistesColumns(protagonistes);

    useEffect(() => {
        const get = async () => {
            const { result } = await getElements("protagonistes");
            setProtagonistes(result);
            setLoading(false);
        };

        get();
    }, []);

    const CustomToolbar = () => {
        return <></>;
    };
    const options = TableOptions(CustomToolbar);

    return (
        <Page title="Merolla de Berguedà">
            <Box m={5}>
                <Title
                    title="Protagonistes"
                    button={
                        <IconButton onClick={() => navigate("addProtagonista")}>
                            <Add />
                        </IconButton>
                    }
                />
                <Loading loading={loading}>
                    <Box pt={4} spacing={3}>
                        <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                                data={protagonistes}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>
                    </Box>
                </Loading>
            </Box>
        </Page>
    );
};

export default ProtagonistesAdmin;
