import { Add } from "@mui/icons-material";
import { IconButton, ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CustomButtonAdmin from "../../../components/form/CustomButtonAdmin";
import Loading from "../../../components/layouts/Loading";
import Page from "../../../components/layouts/Page";
import Title from "../../../components/layouts/Title";
import { getElements } from "../../../database/API";
import getMuiTheme from "../../../theme/TableStyle";
import ActivitatsColumns from "./table/ActivitatsColumns";
import TableOptions from "../../../components/TableOptions";

const HomeAdmin = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [activitats, setActivitats] = useState([]);
    const columns = ActivitatsColumns(activitats);

    useEffect(() => {
        const get = async () => {
            const { result } = await getElements("activitats");
            setActivitats(result);
            setLoading(false);
        };

        get();
    }, []);

    const CustomToolbar = () => {
        return <></>;
    };
    const options = TableOptions(CustomToolbar);

    return (
        <Page title="Merolla de Berguedà">
            <Box m={5}>
                <Title
                    title="Activitats"
                    button={
                        <IconButton onClick={() => navigate("addActivitat")}>
                            <Add />
                        </IconButton>
                    }
                />
                <Loading loading={loading}>
                    <Box pt={4} spacing={3}>
                        <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                                data={activitats}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>
                    </Box>
                </Loading>
            </Box>
        </Page>
    );
};

export default HomeAdmin;
