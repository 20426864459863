import { CircularProgress, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import { getElements } from "../../../database/API";
import bg from "../../../assets/fons/background_fost.jpg";
import img from "../../../assets/headers/protagonistes.jpeg";
import ProtagonistaElement from "./components/ProtagonistaElement";
import Header from "../../../components/layouts/Header";
import Loading from "../../../components/layouts/Loading";
import { useTranslation } from "react-i18next";

const Protagonistes = () => {
    const classes = useStyles();
    const [protagonistes, setProtagonistes] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const get = async () => {
            const { result } = await getElements("protagonistes");
            setProtagonistes(result);
            console.log(result);
            setLoading(false);
        };
        get();
    }, []);

    return (
        <Page title={"Merolla de Berguedà - " + t("les_protagonistes")}>
            <Header title={t("les_protagonistes")} img={img} />
            <Box className={classes.main}>
                <Container>
                    <Loading loading={loading}>
                        <Box>
                            {protagonistes.map((item, index) => (
                                <ProtagonistaElement
                                    protagonista={item}
                                    index={index}
                                />
                            ))}
                        </Box>
                    </Loading>
                </Container>
            </Box>
        </Page>
    );
};

export default Protagonistes;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
    },
    header: {
        height: 200,
        marginTop: 200,
    },
}));
