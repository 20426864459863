import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/fons/background_fost.jpg";
import Header from "../../../components/layouts/Header";

const PoliticaPrivacitat = () => {
    const classes = useStyles();

    return (
        <Page title="Merolla de Berguedà - Politica de Privacitat">
            <Header title="Politica de Privacitat" />
            <Box className={classes.main}>
                <Container>
                    <Box>
                        <Typography variant="h5" pb={2} pt={4}>
                            Tractament de les dades de contactes web
                        </Typography>

                        <Typography>
                            Informació bàsica sobre protecció de dades
                        </Typography>
                        <Typography>Responsable: Merolla Berguedà</Typography>
                        <Typography>
                            Finalitat: Atendre la vostra sol·licitud i
                            enviar-vos comunicacions comercials
                        </Typography>
                        <Typography>
                            Legitimació: Execució dun contracte.
                        </Typography>
                        <Typography>Consentiment de linteressat.</Typography>

                        <Typography>
                            Interès legítim del Responsable.
                        </Typography>

                        <Typography>
                            Destinataris: No se cediran dades a tercers, excepte
                            obligació legal.
                        </Typography>
                        <Typography>
                            Drets: Té dret a accedir, rectificar i suprimir les
                            dades, així com altres drets, indicats a la
                            informació addicional, que pot exercir dirigint-se a
                            la direcció del responsable del tractament
                        </Typography>
                        <Typography>
                            Procedència: El mateix interessat
                        </Typography>

                        <Typography>
                            Informació completa sobre Protecció de Dades
                        </Typography>

                        <Typography variant="h5" pb={2} pt={4}>
                            QUI ÉS EL RESPONSABLE DEL TRACTAMENT DE LES VOSTRES
                            DADES?
                        </Typography>

                        <Typography variant="h5" pb={2} pt={4}>
                            AMB QUINA FINALITAT TRACTEM LES VOSTRES DADES
                            PERSONALS?
                        </Typography>
                        <Typography>
                            A Merolla Berguedà tractem la informació que ens
                            faciliten les persones interessades per tal de
                            realitzar la gestió hotelera de potencials clients
                            que s’han interessat sobre els nostres productes i/o
                            serveis, així com altres contactes comercials i
                            realitzar, si escau, el tramesa de comunicacions
                            comercials inclusivament per via electrònica. En cas
                            que no faciliteu les vostres dades personals, no
                            podrem complir amb les finalitats descrites.
                        </Typography>

                        <Typography>
                            No es prendran decisions automatitzades en base a
                            les dades proporcionades.
                        </Typography>

                        <Typography variant="h5" pb={2} pt={4}>
                            PER QUANT DE TEMPS CONSERVAREM LES VOSTRES DADES?
                        </Typography>
                        <Typography>
                            Les dades es conservaran mentre l’interessat no
                            sol·licita la supressió.
                        </Typography>

                        <Typography variant="h5" pb={2} pt={4}>
                            QUINA ÉS LA LEGITIMACIÓ PER AL TRACTAMENT DE LES
                            VOSTRES DADES?
                        </Typography>
                        <Typography>
                            Us indiquem la base legal per al tractament de les
                            vostres dades:
                        </Typography>

                        <Typography>
                            Execució d’un contracte: Gestió de clients
                            potencials que s’han interessat sobre els nostres
                            productes i/o serveis. (RGPD, art. 6.1.b).
                        </Typography>
                        <Typography>
                            Consentiment de l’interessat: Enviar comunicacions
                            comercials, inclusivament per via electrònica.
                            (RGPD, art. 6.1.a, LSSICE art.21).
                        </Typography>
                        <Typography>
                            Interès legítim del Responsable: Gestió de les dades
                            de contacte professionals (LOPDGDD art.19, RGPD art.
                            6.1.f).
                        </Typography>

                        <Typography variant="h5" pb={2} pt={4}>
                            A QUINS DESTINATARIS ES COMUNICARAN LES VOSTRES
                            DADES?
                        </Typography>
                        <Typography>
                            No se cediran dades a tercers, excepte obligació
                            legal.
                        </Typography>

                        <Typography variant="h5" pb={2} pt={4}>
                            TRANSFERÈNCIES DE DADES A TERCERS PAÏSOS
                        </Typography>
                        <Typography>
                            No estan previstes transferències de dades a tercers
                            països.
                        </Typography>

                        <Typography variant="h5" pb={2} pt={4}>
                            QUINS SÓN ELS VOSTRES DRETS QUAN ENS FACILITA LES
                            VOSTRES DADES?
                        </Typography>
                        <Typography>
                            Qualsevol persona té dret a obtenir confirmació
                            sobre si a Merolla Berguedà estem tractant, o no,
                            dades personals que els concerneixin.
                        </Typography>

                        <Typography>
                            Les persones interessades tenen dret a accedir a les
                            seves dades personals, així com a sol·licitar la
                            rectificació de les dades inexactes o, si escau,
                            sol·licitar la seva supressió quan, entre altres
                            motius, les dades ja no siguin necessàries per als
                            fins que van ser recollides. Igualment, té dret a la
                            portabilitat de les seves dades.
                        </Typography>

                        <Typography>
                            En determinades circumstàncies, els interessats
                            podran sol·licitar la limitació del tractament de
                            les seves dades, cas en què únicament les
                            conservarem per a l’exercici o la defensa de
                            reclamacions.
                        </Typography>

                        <Typography>
                            En determinades circumstàncies i per motius
                            relacionats amb la seva situació particular, els
                            interessats podran oposar-se al tractament de les
                            dades. En aquest cas, Merolla Berguedà deixarà de
                            tractar les dades, tret de motius legítims
                            imperiosos, o l’exercici o la defensa de possibles
                            reclamacions.
                        </Typography>

                        <Typography>
                            Podrà exercitar materialment els seus drets de la
                            manera següent: dirigint-se a la direcció del
                            responsable del tractament
                        </Typography>

                        <Typography>
                            Quan es faci l’enviament de comunicacions comercials
                            utilitzant com a base jurídica l’interès legítim del
                            responsable, l’interessat es pot oposar al
                            tractament de les dades amb aquesta finalitat.
                        </Typography>

                        <Typography>
                            Si heu atorgat el vostre consentiment per a alguna
                            finalitat concreta, teniu dret a retirar el
                            consentiment atorgat en qualsevol moment, sense que
                            això afecti la licitud del tractament basat en el
                            consentiment previ a la seva retirada.
                        </Typography>

                        <Typography>
                            En cas que senti vulnerats els seus drets pel que fa
                            a la protecció de les seves dades personals,
                            especialment quan no hagi obtingut satisfacció en
                            l’exercici dels seus drets, pot presentar una
                            reclamació davant de l’Autoritat de Control en
                            matèria de protecció de dades competent a través del
                            vostre lloc web: www.aepd.es.
                        </Typography>

                        <Typography variant="h5" pb={2} pt={4}>
                            COM HEM OBTINGUT LES VOSTRES DADES?
                        </Typography>
                        <Typography>
                            Les dades personals que tractem a Merolla Berguedà,
                            S.L. procedeixen de: El mateix interessat.
                        </Typography>

                        <Typography>
                            Les categories de dades que es tracten són:
                        </Typography>

                        <Typography>Dades identificatives.</Typography>
                    </Box>
                </Container>
            </Box>
        </Page>
    );
};

export default PoliticaPrivacitat;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
    },
    header: {
        height: 200,
        marginTop: 200,
    },
}));
