import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Header from "../../../components/layouts/Header";
import Page from "../../../components/layouts/Page";
import img from "../../../assets/img/escoles.png";
import bg from "../../../assets/fons/background_fost.jpg";
import alpaca from "../../../assets/img/alpaca-cap-2.png";
import { ReactComponent as Simbol1 } from "../../../assets/simbols/simbol3.svg";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const Escoles = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Page title={"Merolla de Berguedà - " + t("escoles")}>
            <Header title={t("escoles")} img={img} />
            <Box className={classes.main}>
                <Container maxWidth="md" style={{ zIndex: 1 }}>
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Grid item md={6} textAlign="left">
                            <img
                                src={img}
                                className={classes.img}
                                alt="Merolla de Berguedà"
                            />
                        </Grid>
                        <Grid item md={6} position="relative">
                            <Simbol1
                                className={
                                    matches
                                        ? classes.simbol1
                                        : classes.simbol1_m
                                }
                            />
                            <Typography variant="h2" mb={4}>
                                {t("projecte")}
                            </Typography>
                            <Typography variant="body1">
                                {t("escoles_p1")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box textAlign={"center"} mt={3}>
                        <CustomButton
                            title={t("contacta")}
                            onClick={() => navigate("/contacte")}
                        />
                    </Box>
                </Container>
                {matches && (
                    <img
                        src={alpaca}
                        alt="Merolla de Berguedà"
                        className={classes.alpaca}
                    />
                )}
            </Box>
        </Page>
    );
};

export default Escoles;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
        position: "relative",
        zIndex: 0,
    },
    main_m: {
        height: 700,
        background: `url(${bg})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
    },
    img: {
        width: "100%",
        maxWidth: 400,
        zIndex: 0,
    },
    simbol1: {
        width: 60,
        fill: theme.color.secondary,
        position: "absolute",
        left: -30,
        top: 20,
        transform: "rotate(-10deg)",
    },
    simbol2: {
        width: 50,
        fill: theme.color.primary,
        position: "absolute",
        right: 90,
        top: "32%",
        transform: "scaleX(-1)",
    },
    simbol1_m: {
        width: 60,
        fill: theme.color.secondary,
        position: "absolute",
        left: 0,
        top: -30,
        transform: "rotate(-10deg)",
    },
    alpaca: {
        position: "absolute",
        width: 400,
        right: 0,
        bottom: 0,
    },
}));
