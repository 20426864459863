import i18n from "../lang";
import { useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { Box, InputBase, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Language } from "@mui/icons-material";

const BootstrapInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "transparent",
        border: 1,
        fontSize: 13,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            borderColor: "transparent",
            boxShadow: "0",
            backgroundColor: "transparent",
        },
    },
}))(InputBase);

const BootstrapInputMobile = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "transparent",
        border: 1,
        fontSize: 13,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            borderColor: "transparent",
            boxShadow: "0",
            backgroundColor: "transparent",
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    menuItem: {
        fontSize: "15px !important",
        fontWeight: 400,
        marginRight: 10,
    },
    icon: {
        transition: "0.2s !important",
    },
}));

const LangSelector = ({ mobile }) => {
    const classes = useStyles();
    const [lang, setLang] = useState(i18n.language);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const changeLang = (lang) => {
        i18n.changeLanguage(lang);
        setLang(lang);
        enqueueSnackbar(t("idioma_canviat"), {
            variant: "success",
        });
    };

    return (
        <Box ml={3}>
            <Language
                color="primary"
                style={{ fontSize: 15, marginRight: 8 }}
            />
            <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={lang}
                input={mobile ? <BootstrapInputMobile /> : <BootstrapInput />}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                className={classes.arrow}
                MenuProps={{ disableScrollLock: true }}
            >
                <MenuItem
                    className={classes.menuItem}
                    value={"ca"}
                    onClick={() => changeLang("ca")}
                >
                    Ca
                </MenuItem>
                <MenuItem
                    className={classes.menuItem}
                    value={"es"}
                    onClick={() => changeLang("es")}
                >
                    Es
                </MenuItem>
            </Select>
        </Box>
    );
};

export default LangSelector;
