/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import Title from "../../../components/layouts/Title";
import Loading from "../../../components/layouts/Loading";
import CustomCard from "../../../components/form/CustomCard";
import CustomTextField from "../../../components/form/CustomTextField";
import Thumb from "../../../components/form/Thumb";
import ImageInput from "../../../components/form/ImageInput";
import CustomButtonAdmin from "../../../components/form/CustomButtonAdmin";
import {
    createActivitat,
    getElement,
    updateActivitat,
} from "../../../database/API";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingBottom: 50,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    header: {
        borderBottom: "1px solid " + theme.palette.background.light,
    },
}));

const ActivitatAdd = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [activitat, setActivitat] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const { key } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        getValues,
        reset,
    } = useForm({});

    useEffect(() => {
        const get = async () => {
            const { result } = await getElement("activitats", key);
            setActivitat(result);
            reset(result);
            setLoading(false);
        };

        if (key) get();
        else setLoading(false);
    }, [key]);

    const enviar = async (values) => {
        setLoading(true);
        if (key) {
            const message = await updateActivitat(values, key);
            enqueueSnackbar(message, {
                variant: "success",
            });
        } else {
            const message = await createActivitat(values);
            enqueueSnackbar(message, {
                variant: "success",
            });
        }

        navigate(-1, { state: values });
    };

    return (
        <Box className={classes.container}>
            <Title title={key ? "Editar activitat" : "Afegir activitat"} />
            <Loading loading={loading}>
                <Container maxWidth="xl">
                    <Box mt={4}>
                        <form onSubmit={handleSubmit(enviar)}>
                            <Grid container spacing={5}>
                                <Grid item md={8}>
                                    <CustomCard title="Dades">
                                        <Grid spacing={3} container>
                                            <Grid item md={6}>
                                                <CustomTextField
                                                    name="nom"
                                                    label="Nom"
                                                    type="text"
                                                    errors={errors.nom}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <CustomTextField
                                                    name="nom_es"
                                                    label="Nom castellà"
                                                    type="text"
                                                    errors={errors.nom_es}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid spacing={3} container>
                                            <Grid item md={3}>
                                                <CustomTextField
                                                    name="duracio"
                                                    label="Duració"
                                                    type="text"
                                                    errors={errors.duracio}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={3}>
                                                <CustomTextField
                                                    name="preu_adult"
                                                    label="Preu adult"
                                                    type="double"
                                                    errors={errors.preu_adult}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={3}>
                                                <CustomTextField
                                                    name="preu_4"
                                                    label="Preu per més de 4 persones"
                                                    type="double"
                                                    errors={errors.preu_4}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={3}>
                                                <CustomTextField
                                                    name="preu_nen"
                                                    label="Preu nen"
                                                    type="double"
                                                    errors={errors.preu_nen}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid spacing={3} container>
                                            <Grid item md={12}>
                                                <CustomTextField
                                                    name="descripcio"
                                                    label="Descripció curta"
                                                    type="text"
                                                    multiline
                                                    rows={3}
                                                    errors={errors.descripcio}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <CustomTextField
                                                    name="descripcio_es"
                                                    label="Descripció curta castellà"
                                                    type="text"
                                                    multiline
                                                    rows={3}
                                                    errors={
                                                        errors.descripcio_es
                                                    }
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <CustomTextField
                                                    name="text"
                                                    label="Text"
                                                    type="text"
                                                    multiline
                                                    rows={10}
                                                    errors={errors.text}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <CustomTextField
                                                    name="text_es"
                                                    label="Text castellà"
                                                    type="text"
                                                    multiline
                                                    rows={10}
                                                    errors={errors.text_es}
                                                    register={register}
                                                    InputLabelProps={{
                                                        shrink: key,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <CustomButtonAdmin
                                            title="Guardar"
                                            type="submit"
                                        />
                                    </CustomCard>
                                </Grid>
                                <Grid item md={4}>
                                    <CustomCard title="Imatge">
                                        <Thumb file={getValues("foto")} />

                                        <ImageInput
                                            name="foto"
                                            register={register}
                                            trigger={trigger}
                                            getValues={getValues}
                                        />
                                    </CustomCard>
                                    <Box my={4} />
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Container>
            </Loading>
        </Box>
    );
};

export default ActivitatAdd;
