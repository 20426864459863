import * as React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bg from "../../assets/fons/background_fost.jpg";
import { ReactComponent as Simbol1 } from "../../assets/simbols/simbol1.svg";

export default function Header({ title, img, small, logo }) {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <Box className={classes.header}>
            <Box display="flex" justifyContent={"center"}>
                <Typography variant="h1" fontSize={110} textAlign="center">
                    {title}
                </Typography>

                <Simbol1
                    className={matches ? classes.simbol1 : classes.simbol1_m}
                />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    header: {
        // height: 40,
        background: `url(${bg})`,
        paddingTop: 200,
        paddingBottom: 20,
    },
    simbol1: {
        position: "absolute",
        top: 220,
        left: "40%",
        width: 150,
        fill: theme.color.secondary,
        transform: "rotate(-10deg)",
    },
    simbol1_m: {
        position: "absolute",
        top: 180,
        left: 20,
        width: 130,
        fill: theme.color.secondary,
        transform: "rotate(10deg)",
    },
}));
