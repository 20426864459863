import { Edit } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import { useNavigate } from "react-router";

const ActivitatsColumns = (activitats) => {
    const navigate = useNavigate();
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        },
        {
            name: "foto",
            label: "Foto",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) => (
                    <Avatar
                        variant="circular"
                        src={
                            value &&
                            "https://api.merollabergueda.com/public/storage/" +
                                value
                        }
                    />
                ),
            },
        },
        {
            name: "nom",
            label: "Nom",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
            },
        },
        {
            name: "preu_adult",
            label: "Preu adult",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) => value && value + " €",
            },
        },
        {
            name: "preu_4",
            label: "Preu 4 persones",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) => value && value + " €",
            },
        },
        {
            name: "preu_nen",
            label: "Preu nens",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) => value && value + " €",
            },
        },
        {
            name: "duracio",
            label: "Duració",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
            },
        },
        {
            name: "id",
            label: "Accions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() =>
                                navigate(
                                    `/admin/addActivitat/${activitats[dataIndex].id}`,
                                    {
                                        state: activitats[dataIndex],
                                    }
                                )
                            }
                        >
                            <Edit />
                        </IconButton>
                    );
                },
            },
        },
    ];
    return columns;
};

export default ActivitatsColumns;
