import React from "react";
import { Navigate } from "react-router-dom";
import Home from "./views/public/home/Home";
import AdminLayout from "./layouts/adminLayout";
import PublicLayout from "./layouts/publicLayout";
import LoginView from "./views/auth/LoginView";
import HomeAdmin from "./views/admin/homeAdmin/HomeAdmin";
import EnConstruccio from "./views/EnConstruccio";
import ActivitatAdd from "./views/admin/homeAdmin/ActivitatAdd";
import ProtagonistesAdmin from "./views/admin/protagonistesAdmin/ProtagonistesAdmin";
import ProtagonistaAdd from "./views/admin/protagonistesAdmin/ProtagonistaAdd";
import Protagonistes from "./views/public/protagonistes/Protagonistes";
import QuiSom from "./views/public/quiSom/QuiSom";
import Activitats from "./views/public/activitats/Activitats";
import Contacte from "./views/public/contacte/Contacte";
import Escoles from "./views/public/escoles/Escoles";
import Espai from "./views/public/espai/Espai";
import AvisLegal from "./views/public/legal/AvisLegal";
import PoliticaPrivacitat from "./views/public/legal/PoliticaPrivacitat";
import ActivitatSingle from "./views/public/activitats/ActivitatSingle";

const routes = (logged) => [
    {
        path: "/admin",
        element: logged ? <AdminLayout /> : <Navigate to="/login" />,
        children: [
            {
                path: "/admin/activitats",
                element: <HomeAdmin title="Home" />,
            },
            {
                path: "/admin/addActivitat/:key",
                element: <ActivitatAdd />,
            },
            {
                path: "/admin/activitats/addActivitat",
                element: <ActivitatAdd />,
            },
            {
                path: "/admin/protagonistes",
                element: <ProtagonistesAdmin />,
            },
            {
                path: "/admin/protagonistes/addProtagonista/:key",
                element: <ProtagonistaAdd />,
            },
            {
                path: "/admin/protagonistes/addProtagonista",
                element: <ProtagonistaAdd />,
            },
        ],
    },
    {
        path: "/",
        element: <PublicLayout />,
        children: [
            {
                path: "/login",
                element: !logged ? (
                    <LoginView />
                ) : (
                    <Navigate to="/admin/activitats" />
                ),
            },
            { path: "/", element: <Home /> },
            { path: "/protagonistes", element: <Protagonistes /> },
            { path: "/qui-som", element: <QuiSom /> },
            { path: "/activitats", element: <Activitats /> },
            { path: "/activitats/:id", element: <ActivitatSingle /> },
            { path: "/contacte", element: <Contacte /> },
            { path: "/escoles", element: <Escoles /> },
            { path: "/espai", element: <Espai /> },
            { path: "/avis-legal", element: <AvisLegal /> },
            { path: "/politica-privacitat", element: <PoliticaPrivacitat /> },
            {
                path: "/",
                element: <EnConstruccio />,
            },
        ],
    },
];

export default routes;
