import {
    Alert,
    CircularProgress,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import { getElements } from "../../../database/API";
import bg from "../../../assets/fons/background_fost.jpg";
import img from "../../../assets/headers/protagonistes.jpeg";
import Header from "../../../components/layouts/Header";
import Loading from "../../../components/layouts/Loading";
import ActivitatList from "../home/components/ActivitatList";
import { useTranslation } from "react-i18next";

const Activitats = () => {
    const classes = useStyles();
    const [activitats, setActivitats] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const get = async () => {
            const { result } = await getElements("activitats");
            setActivitats(result);
            setLoading(false);
        };
        get();
    }, []);

    return (
        <Page title={"Merolla de Berguedà - " + t("activitats")}>
            <Header title={t("activitats")} img={img} />

            <Box className={classes.main}>
                <Container>
                    <Typography variant="body1">
                        {t("activitats-tots")}
                    </Typography>
                    <Box mb={2} />
                    <Alert severity="warning">{t("alerta-activitats")}</Alert>
                    <Box mb={5} />
                    <Loading loading={loading}>
                        <Grid container spacing={3}>
                            {activitats.map((item, index) => (
                                <ActivitatList item={item} index={index} />
                            ))}
                        </Grid>
                    </Loading>
                </Container>
            </Box>
        </Page>
    );
};

export default Activitats;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
    },
    header: {
        height: 200,
        marginTop: 200,
    },
}));
