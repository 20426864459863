import Page from "../../../components/layouts/Page";
import Activitats from "./Activitats";
import Hola from "./Hola";
import Portada from "./Portada";

const Home = () => {
    return (
        <Page title="Merolla de Berguedà">
            <Portada />
            <Activitats />
            <Hola />
        </Page>
    );
};

export default Home;
