import { createTheme } from "@mui/material";

const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        boxShadow: "#00000010 1px 3px 10px 1px ",
                        borderRadius: 5,
                        paddingTop: 20,
                        zIndex: 0,
                        marginBottom: 80,
                        backgroundColor: "white",
                        overflow: "hidden",
                        position: "relative",
                    },
                },
            },
        },
    });

export default getMuiTheme;
