import { Modal, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Page from "../components/layouts/Page";
import img from "../assets/img/logo.svg";
import bg from "../assets/fons/background_clar.jpg";
const EnConstruccio = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <Page title="Merolla de Berguedà">
            <Box className={matches ? classes.main : classes.main_m}>
                <img width={200} src={img} alt="Logo" />
                <Typography>Pàgina en construcció</Typography>
            </Box>
        </Page>
    );
};

export default EnConstruccio;

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: -150,
        width: "100%",
        height: "100vh",
        background: `url(${bg})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        zIndex: 1000,
    },
    main_m: {
        height: 700,
        background: `url(${img})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
    },
    img: {
        width: 700,
    },
    img_m: {
        width: "100%",
    },
    alpaca: {
        width: 300,
        zIndex: 1,
    },
    alpaca_inv: {
        width: 300,
        zIndex: 1,
        transform: "scaleX(-1)",
    },
}));
