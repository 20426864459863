import { Edit } from "@mui/icons-material";
import { Avatar, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router";

const ProtagonistesColumns = (protagonistes) => {
    const navigate = useNavigate();
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        },
        {
            name: "foto",
            label: "Foto",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) => (
                    <Avatar
                        variant="circular"
                        src={
                            value &&
                            "https://api.merollabergueda.com/public/storage/" +
                                value
                        }
                        sx={{ width: 56, height: 56 }}
                    />
                ),
            },
        },
        {
            name: "nom",
            label: "Nom",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) => (
                    <Typography variant="body1">{value}</Typography>
                ),
            },
        },
        {
            name: "descripcio",
            label: "Descripció",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
            },
        },

        {
            name: "id",
            label: "Accions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() =>
                                navigate(
                                    `/admin/protagonistes/addProtagonista/${protagonistes[dataIndex].id}`,
                                    {
                                        state: protagonistes[dataIndex],
                                    }
                                )
                            }
                        >
                            <Edit />
                        </IconButton>
                    );
                },
            },
        },
    ];
    return columns;
};

export default ProtagonistesColumns;
