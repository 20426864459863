import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import theme from "../theme";
import { ReactComponent as ButtonImg } from "../assets/img/button.svg";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Typography } from "@mui/material";

const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: 30,
    // padding: "10px 20px",
    borderRadius: 5,
    lineHeight: 1,
    color: "white",
    backgroundColor: "transparent",
    borderColor: theme.palette.background.main,
    fontFamily: "Raleway",
    "&:hover": {
        backgroundColor: "transparent",
        color: "white",
        borderColor: "transparent",
        boxShadow: "none",
        "& #svg": {
            fill: theme.color.primary,
        },
        "& .MuiTypography-root": {
            color: "white",
        },
        "&:.icon": {
            color: "white",
        },
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "transparent",
        borderColor: "transparent",
    },
    "&:focus": {
        boxShadow: "none",
    },
    "&.Mui-disabled": {
        backgroundColor: "transparent",
    },
});

export default function CustomButton({ title, danger, loading, ...rest }) {
    const classes = useStyles();
    return (
        <BootstrapButton
            {...rest}
            variant="contained"
            style={{
                paddingRight: 40,
                paddingLeft: 40,
                paddingTop: 20,
                paddingBottom: 20,
            }}
            disabled={loading}
        >
            <ButtonImg className={classes.img} id="svg" />
            {loading ? (
                <CircularProgress size={30} />
            ) : (
                <Typography variant="body1" style={{ zIndex: 1 }}>
                    {title}
                </Typography>
            )}
        </BootstrapButton>
    );
}

const useStyles = makeStyles((theme) => ({
    img: {
        position: "absolute",
        transition: "0.2s",
        zIndex: 0,
        fill: theme.color.secondary,
        height: 40,
    },
}));
