import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import bg from "../../../assets/fons/background_fost.jpg";
import img from "../../../assets/img/foto-holahola.png";
import alpaca from "../../../assets/img/alpaca-cap.png";
import CustomButton from "../../../components/CustomButton";
import { ReactComponent as Simbol1 } from "../../../assets/simbols/simbol1.svg";
import { ReactComponent as Simbol2 } from "../../../assets/simbols/simbol2.svg";
import { useNavigate, useNavigation } from "react-router";
import { useTranslation } from "react-i18next";

const Hola = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Box className={classes.main}>
            <Container maxWidth="md" style={{ zIndex: 1 }}>
                <Grid
                    container
                    spacing={2}
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Grid item md={6} position="relative">
                        <Simbol1
                            className={
                                matches ? classes.simbol1 : classes.simbol1_m
                            }
                        />
                        {matches ? <Simbol2 className={classes.simbol2} /> : ""}
                        <Typography variant="h1">HOLA, HOLA!</Typography>
                        <Typography
                            variant="h2"
                            style={{ whiteSpace: "pre-line" }}
                        >
                            {t("soc_la")}
                        </Typography>
                        <Typography variant="h1" mb={3}>
                            {t("les_alpaques")}
                        </Typography>
                        <CustomButton
                            onClick={() => navigate("/qui-som")}
                            title={t("coneix")}
                        />
                    </Grid>
                    <Grid item md={6} textAlign="left">
                        <img
                            src={img}
                            className={classes.img}
                            alt="Merolla de Berguedà"
                        />
                    </Grid>
                </Grid>
            </Container>
            {matches && (
                <img
                    src={alpaca}
                    alt="Merolla de Berguedà"
                    className={classes.alpaca}
                />
            )}
        </Box>
    );
};

export default Hola;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        position: "relative",
        paddingTop: 200,
        paddingBottom: 200,
    },
    main_m: {
        height: 700,
        background: `url(${bg})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
    },
    img: {
        width: "100%",
        maxWidth: 400,
        zIndex: 0,
    },
    simbol1: {
        width: 120,
        fill: theme.color.secondary,
        position: "absolute",
        left: -10,
        top: 20,
        transform: "rotate(-10deg)",
    },
    simbol1_m: {
        width: 110,
        fill: theme.color.secondary,
        position: "absolute",
        left: -10,
        top: 10,
        transform: "rotate(-10deg)",
    },
    simbol2: {
        width: 50,
        fill: theme.color.primary,
        position: "absolute",
        right: 90,
        top: "32%",
        transform: "scaleX(-1)",
    },
    alpaca: {
        position: "absolute",
        width: 400,
        left: 0,
        bottom: 0,
    },
}));
