import {
    Alert,
    Container,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Header from "../../../components/layouts/Header";
import Page from "../../../components/layouts/Page";
import img from "../../../assets/headers/quisom.jpeg";
import img2 from "../../../assets/img/contacte.png";
import bg from "../../../assets/fons/background_fost.jpg";
import alpaca from "../../../assets/img/alpaca-cap.png";
import { ReactComponent as Simbol1 } from "../../../assets/simbols/simbol3.svg";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/CustomButton";
import CustomTextField from "../../../components/form/CustomTextField";
import { contacte } from "../../../database/API";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Contacte = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({});

    const enviar = async (values) => {
        setLoading(true);
        const message = await contacte(values);
        enqueueSnackbar(message, {
            variant: "success",
        });
        reset();
        setLoading(false);
    };

    return (
        <Page title={"Merolla de Berguedà - " + t("contacta")}>
            <Header title={t("contacta")} img={img} />
            <Box className={classes.main}>
                <Container maxWidth="md" style={{ zIndex: 1 }}>
                    <Alert severity="warning">{t("alerta-activitats")}</Alert>
                    <Box mb={5} />
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Grid item md={6} position="relative">
                            <Simbol1
                                className={
                                    matches
                                        ? classes.simbol1
                                        : classes.simbol1_m
                                }
                            />
                            <Typography variant="h1">{t("digans")}</Typography>
                            <Box mb={3} />

                            <Typography variant="body1">
                                {t("activitats-tots")}
                            </Typography>
                            <Box mb={4} />
                            <Typography variant="body1" mb={4}>
                                {t("trucans")}{" "}
                                <a href="tel:+34649126476">649 12 64 76</a>{" "}
                                {t("o_mis")}{" "}
                            </Typography>
                            <Box mb={3} />
                            <form onSubmit={handleSubmit(enviar)}>
                                <Grid spacing={3} container>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            name="nom"
                                            label={t("nom")}
                                            type="text"
                                            errors={errors.nom}
                                            register={register}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid spacing={3} container>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            name="email"
                                            label="E-mail"
                                            type="text"
                                            errors={errors.duracio}
                                            register={register}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid spacing={3} container>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            name="missatge"
                                            label={t("Missatge")}
                                            type="text"
                                            multiline
                                            rows={3}
                                            errors={errors.descripcio}
                                            register={register}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign="center">
                                        <CustomButton
                                            loading={loading}
                                            title={t("Enviar")}
                                            type="submit"
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item md={6} textAlign="center">
                            <img
                                src={img2}
                                className={classes.img}
                                alt="Merolla de Berguedà"
                            />
                        </Grid>
                    </Grid>
                </Container>
                {matches && (
                    <img
                        src={alpaca}
                        alt="Merolla de Berguedà"
                        className={classes.alpaca}
                    />
                )}
            </Box>
        </Page>
    );
};

export default Contacte;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
    },
    main_m: {
        height: 700,
        background: `url(${bg})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
    },
    img: {
        width: "100%",
        maxWidth: 400,
        zIndex: 0,
    },
    simbol1: {
        width: 60,
        fill: theme.color.secondary,
        position: "absolute",
        left: -30,
        top: 30,
        transform: "rotate(-10deg)",
    },
    simbol1_m: {
        width: 60,
        fill: theme.color.secondary,
        position: "absolute",
        left: 0,
        top: -30,
        transform: "rotate(-10deg)",
    },
    simbol2: {
        width: 50,
        fill: theme.color.primary,
        position: "absolute",
        right: 90,
        top: "32%",
        transform: "scaleX(-1)",
    },
    alpaca: {
        position: "absolute",
        width: 400,
        left: 0,
        bottom: 0,
    },
}));
