import { Hidden, Modal, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import portada from "../../../assets/img/portada-3.jpg";
import mask from "../../../assets/simbols/mascara-portada.svg";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import img from "../../../assets/fons/background_clar.jpg";

const Portada = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <Box className={classes.root}>
            <Box className={classes.mask}>
                <Box className={matches ? classes.main : classes.main_m}>
                    <Hidden mdDown>
                        <Logo
                            className={matches ? classes.logo : classes.logo_m}
                        />
                    </Hidden>
                </Box>
            </Box>
        </Box>
    );
};

export default Portada;

const useStyles = makeStyles((theme) => ({
    root: {
        background: `url(${img})`,
        marginTop: 90,
    },
    main: {
        height: "100vh",
        background: `url(${portada})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        overflowX: "hidden",
    },
    main_m: {
        height: 500,
        background: `url(${portada})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        overflowX: "hidden",
    },
    mask: {
        width: "100%",
        mask: `url(${mask})`,
        maskSize: "cover",
        maskRepeat: "no-repeat",
        maskPosition: "center",
        zIndex: 0,
    },
    logo: {
        width: 200,
        marginTop: 80,
    },

    logo_m: {
        width: 160,
        marginTop: 20,
    },
}));
