import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Icon } from "../../assets/img/logo_inv.svg";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Container } from "@mui/system";
import { ReactComponent as TikTok } from "../../assets/img/tiktok.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.root}>
            <Container>
                <Grid container spacing={3} className={classes.main}>
                    <Grid item md={4}>
                        <Typography variant="footer">
                            {t("footer_text")}
                        </Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Box className={classes.header} mb={3}>
                            <a href="/">
                                <Icon
                                    alt="Merolla de Berguedà"
                                    width={200}
                                    className={classes.logo}
                                />
                            </a>
                        </Box>
                        <Box display={"flex"} justifyContent="center">
                            <Button
                                className={classes.iconWrap}
                                onClick={() =>
                                    window.open(
                                        "https://www.instagram.com/merollabergueda/"
                                    )
                                }
                            >
                                <Instagram className={classes.icon} />
                            </Button>

                            <Button
                                className={classes.iconWrap}
                                onClick={() =>
                                    window.open(
                                        "https://www.facebook.com/merollabergueda"
                                    )
                                }
                            >
                                <Facebook className={classes.icon} />
                            </Button>
                            {/* <Button className={classes.iconWrap} onClick={() => window.open("")}>
                                <Twitter className={classes.icon} />
                            </Button> */}
                            <Button
                                className={classes.iconWrap}
                                onClick={() =>
                                    window.open(
                                        "https://www.tiktok.com/@merollabergueda?lang=es"
                                    )
                                }
                            >
                                <TikTok
                                    className={classes.iconSVG}
                                    fontSize={10}
                                />
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item md={4}>
                        <Box mt={2} display="flex" flexDirection={"column"}>
                            <a
                                href="/avis-legal"
                                style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                }}
                            >
                                <Typography variant="footer">
                                    {t("avis")}
                                </Typography>
                            </a>

                            <a
                                href="/politica-privacitat"
                                style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                }}
                            >
                                <Typography variant="footer">
                                    {t("politica")}
                                </Typography>
                            </a>
                            <Typography variant="footer">
                                {t("disseny")}{" "}
                                <a
                                    href="https://la-padrina.cat"
                                    target="_blank"
                                    style={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                    }}
                                    rel="noreferrer"
                                >
                                    <Typography variant="footer">
                                        La Padrina
                                    </Typography>
                                </a>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.secondary,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 100,
        display: "flex",
        alignItems: "center",
    },
    header: {
        textAlign: "center",
    },
    logo: {
        fill: theme.color.background,
    },
    icon: {
        width: 60,
        height: 60,
        border: "1px solid " + theme.color.background,
        borderRadius: 50,
        padding: 10,
        color: theme.color.background,
    },
    iconSVG: {
        width: 25,
        height: 25,
        border: "1px solid " + theme.color.background,
        borderRadius: 50,
        padding: 10,
        fill: theme.color.background,
        fontSize: 20,
    },
    iconWrap: {
        "&.MuiButtonBase-root": {
            borderRadius: 50,
        },
    },
}));
