import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    item: {
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
        transition: "0.5s",
    },
    button: {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: "flex-start",
        letterSpacing: 0,
        padding: "10px 8px",
        textTransform: "none",
        width: "100%",
        "&:hover": {
            backgroundColor:
                theme.palette.text.secondary + "AA" + " !important",
            color: "white",
            "& $icon": {
                color: "white",
            },
        },
        "&.active": {
            backgroundColor: theme.palette.text.secondary,
            color: "white",
            boxShadow: "0px 0px 8px 0px " + theme.palette.text.secondary,

            "& $title": {
                fontWeight: "700",
            },
            "& $icon": {
                color: "white",
            },
            "&:hover": {
                backgroundColor: theme.palette.text.secondary,
                color: "white",
                "& $icon": {
                    color: "white",
                },
            },
        },
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
    },
    title: {
        // color: theme.palette.text.secondary,
        marginRight: "auto",
    },
}));

const NavBarItem = ({
    className,
    href,
    icon: Icon,
    title,
    onClose,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <ListItem
            className={clsx(classes.item, className)}
            disableGutters
            {...rest}
        >
            <Button
                className={classes.button}
                component={RouterLink}
                to={href}
                onClick={onClose}
                color="secondary"
            >
                <Icon className={classes.icon} size="20" />

                <span className={classes.title}>{title}</span>
            </Button>
        </ListItem>
    );
};

NavBarItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavBarItem;
