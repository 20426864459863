import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/fons/background_fost.jpg";
import Header from "../../../components/layouts/Header";

const AvisLegal = () => {
    const classes = useStyles();

    return (
        <Page title="Merolla de Berguedà - Avís Legal">
            <Header title="Avís Legal" />
            <Box className={classes.main}>
                <Container>
                    <Box>
                        <Typography variant="h3" mt={4} mb={2}>
                            OBJECTE
                        </Typography>
                        <Typography>
                            El present avís legal regula l’ús i la utilització
                            del lloc web www.merollabergueda.com del qual és
                            titular Merolla Berguedà, en endavant (EL PROPIETARI
                            DE LA WEB)
                        </Typography>

                        <Typography>
                            La navegació pel lloc web de EL PROPIETARI DE LA WEB
                            atribueix la condició d’usuari del mateix i implica
                            l’acceptació plena i sense reserves de totes i
                            cadascuna de les disposicions incloses en aquest
                            Avís Legal, que poden patir modificacions sense avís
                            previ de EL PROPIETARI DE LA WEB cas en el que es
                            procedirà a la seva publicació i avis el mes aviat
                            possible.
                        </Typography>

                        <Typography>
                            Es recomanable llegir detingudament el seu contingut
                            en cas de voler accedir i fes us de la informació i
                            dels serveis oferts en aquesta web.
                        </Typography>

                        <Typography>
                            L’usuari s’obliga a fer un ús correcte del lloc web
                            de conformitat amb les lleis, la bona fe, l’ordre
                            públic, els usos del tràfic i el present Avís Legal.
                            L’usuari respondrà davant al EL PROPIETARI DE LA WEB
                            o davant de tercers, de qualssevol dany i/o
                            perjudici que poguessin causar com a conseqüència de
                            l’incompliment d’aquesta obligació.
                        </Typography>

                        <Typography>
                            Qualsevol utilització diferent a l’autoritzada queda
                            expressament prohibida, pel qual EL PROPIETARI DE LA
                            WEB es reserva el dret de denegar o retirar l’accés
                            i el seu us en qualsevol moment.
                        </Typography>

                        <Typography variant="h3" mt={4} mb={2}>
                            IDENTIFICACIÓ
                        </Typography>
                        <Typography>
                            EL PROPIETARI DE LA WEB en compliment de la Llei
                            34/2002, de 11 de juliol, de serveis de la societat
                            de la informació i de comerç electrònic, l’informa
                            del següent:
                        </Typography>

                        <Typography>
                            La seva denominació social és: Merolla Berguedà.
                        </Typography>
                        <Typography>El seu CIF es: B63037949</Typography>
                        <Typography>
                            El seu domicili social es a: C/ Ctra. Ribes, Km 44,
                            08600 Berga – Barcelona
                        </Typography>

                        <Typography variant="h3" mt={4} mb={2}>
                            COMUNICACIONS
                        </Typography>
                        <Typography>
                            Para comunicar-se amb nosaltres, posem a la seva
                            disposició diferents mitjans de contacte que li
                            detallem a continuació:
                        </Typography>

                        <Typography>Telèfon: 938210470</Typography>
                        <Typography>Email: info@lacabanaberga,cat</Typography>
                        <Typography>
                            Totes les notificacions i comunicacions entre els
                            usuaris i EL PROPIETARI DE LA WEB es consideraran
                            eficaços, a tots els efectes, quan es realitzin a
                            través de correu postal o qualsevol altre mitjà dels
                            detallats anteriorment.
                        </Typography>

                        <Typography variant="h3" mt={4} mb={2}>
                            CONDICIONS D’ACCÉS I UTILITZACIÓ
                        </Typography>
                        <Typography>
                            El lloc web i els seus serveis són d’accés lliure i
                            gratuït, no obstant això, EL PROPIETARI DE LA WEB
                            condiciona la utilització d’alguns dels serveis
                            oferts a la seva web al previ emplenament del
                            corresponent formulari.
                        </Typography>

                        <Typography>
                            L’usuari garanteix l’autenticitat i actualitat de
                            totes aquelles dades que comuniqui a EL PROPIETARI
                            DE LA WEB i serà l’únic responsable de les
                            manifestacions falses o inexactes que realitzi.
                        </Typography>

                        <Typography>
                            L’usuari es compromet expressament a fer un ús
                            adequat dels continguts i serveis de EL PROPIETARI
                            DE LA WEB i a no fer-los servir per, entre d’altres:
                        </Typography>

                        <Typography>
                            Difondre continguts, delictius, violents,
                            pornogràfics, racistes, xenòfobs, ofensius,
                            d’apologia del terrorisme o, en general, contraris a
                            la llei o a l’ordre públic.
                        </Typography>
                        <Typography>
                            Introduir en la xarxa virus informàtics o realitzar
                            actuacions susceptibles d’alterar, malmetre,
                            interrompre o generar errors o danys en els
                            documents electrònics, dades o sistemes físics i
                            lògics de EL PROPIETARI DE LA WEB o de terceres
                            persones, així com obstaculitzar l’accés d’altres
                            usuaris al lloc web i als seus serveis mitjançant el
                            consum massiu dels recursos informàtics a través
                            dels quals EL PROPIETARI DE LA WEB presta els seus
                            serveis.
                        </Typography>
                        <Typography>
                            Intentar accedir als comptes de correu electrònic
                            d’altres usuaris o a àrees restringides dels
                            sistemes informàtics de EL PROPIETARI DE LA WEB o de
                            tercers i, en cas de fer-ho, extreure informació.
                        </Typography>
                        <Typography>
                            Vulnerar els drets de propietat intel·lectual o
                            industrial, així com violar la confidencialitat de
                            la informació de EL PROPIETARI DE LA WEB o de
                            tercers.
                        </Typography>
                        <Typography>
                            Suplantar la identitat de qualsevol altre usuari o
                            de les administracions públiques.
                        </Typography>
                        <Typography>
                            Reproduir, copiar, distribuir, posar a disposició o
                            de qualsevol altra forma comunicar públicament,
                            transformar o modificar els continguts, llevat que
                            es compti amb l’autorització del titular dels
                            corresponents drets o això resulti legalment permès.
                        </Typography>
                        <Typography>
                            Recollir dades amb finalitat publicitària i de
                            remetre publicitat de qualsevol classe i
                            comunicacions amb finalitats de venda o altres de
                            naturalesa comercial sense sol·licitud prèvia o
                            sense consentiment.
                        </Typography>
                        <Typography>
                            Tots els continguts del lloc web, com ara textos,
                            fotografies, gràfics, imatges, icones, tecnologia,
                            programari, així com el seu disseny gràfic i codis
                            font, constitueixen una obra de la qual la propietat
                            pertany a EL PROPIETARI DE LA WEB, sense que puguin
                            entendre cedits a l’ usuari cap dels drets
                            d’explotació sobre els mateixos més enllà de
                            l’estrictament necessari per al correcte ús del web.
                        </Typography>

                        <Typography>
                            En definitiva, els usuaris que accedeixin a aquest
                            lloc web poden visualitzar els continguts i
                            efectuar, si escau, còpies privades autoritzades
                            sempre que els elements reproduïts no siguin cedits
                            posteriorment a tercers, ni s’instal·lin a servidors
                            connectats a xarxes, ni siguin objecte de cap tipus
                            d’explotació.
                        </Typography>

                        <Typography>
                            Així mateix, totes les marques, noms comercials o
                            signes distintius de qualsevol classe que apareixen
                            al lloc web són propietat de EL PROPIETARI DE LA
                            WEB, sense que pugui entendre que l’ús o accés al
                            mateix atribueixi a l’usuari dret algun sobre els
                            mateixos.
                        </Typography>

                        <Typography>
                            La distribució, modificació, cessió o comunicació
                            pública dels continguts i qualsevol altre acte que
                            no hagi estat expressament autoritzat pel titular
                            dels drets d’explotació queden prohibits.
                        </Typography>

                        <Typography>
                            L’establiment d’un hiperenllaç no implica en cap cas
                            l’existència de relacions entre EL PROPIETARI DE LA
                            WEB i el propietari del lloc web en la qual
                            s’estableixi, ni l’acceptació i aprovació per part
                            del EL PROPIETARI DE LA WEB dels seus continguts o
                            serveis .
                        </Typography>

                        <Typography>
                            EL PROPIETARI DE LA WEB no es responsabilitza de
                            l’ús que cada usuari li doni als materials posats a
                            disposició en aquest lloc web ni de les actuacions
                            que realitzi en base als mateixos.
                        </Typography>

                        <Typography variant="h3" mt={4} mb={2}>
                            EXCLUSIÓ DE GARANTIES I DE RESPONSABILITAT EN
                            L’ACCÉS I LA UTILITZACIÓ
                        </Typography>
                        <Typography>
                            El contingut del present lloc web és de caràcter
                            general i té una finalitat merament informativa,
                            sense que es garanteixi plenament l’accés a tots els
                            continguts, ni la seva exhaustivitat, correcció,
                            vigència o actualitat, ni la seva idoneïtat o
                            utilitat per a un objectiu específic.
                        </Typography>

                        <Typography>
                            EL PROPIETARI DE LA WEB exclou, fins on permet
                            l’ordenament jurídic, qualsevol responsabilitat pels
                            danys i perjudicis de tota naturalesa derivats de:
                        </Typography>

                        <Typography>
                            a) La impossibilitat d’accés al lloc web o la falta
                            de veracitat, exactitud, exhaustivitat i / o
                            actualitat dels continguts, així com l’existència de
                            vicis i defectes de tota classe dels continguts
                            transmesos, difosos, emmagatzemats, posats a
                            disposició als quals s’hagi accedit a través del
                            lloc web o dels serveis que s’ofereixen.
                        </Typography>
                        <Typography>
                            b) La presència de virus o d’altres elements en els
                            continguts que puguin produir alteracions en els
                            sistemes informàtics, documents electrònics o dades
                            dels usuaris.
                        </Typography>
                        <Typography>
                            c) L’incompliment de les lleis, la bona fe, l’ordre
                            públic, els usos del tràfic i el present avís legal
                            com a conseqüència de l’ús incorrecte del lloc web.
                            En particular, i a tall d’exemple, EL PROPIETARI DE
                            LA WEB no es fa responsable de les actuacions de
                            tercers que vulnerin drets de propietat
                            intel·lectual i industrial, secrets empresarials,
                            drets a l’honor, a la intimitat personal i familiar
                            i a la pròpia imatge, així com la normativa en
                            matèria de competència deslleial i publicitat
                            il·lícita.
                        </Typography>
                        <Typography>
                            Així mateix, EL PROPIETARI DE LA WEB declina
                            qualsevol responsabilitat respecte a la informació
                            que es trobi fora d’aquesta web i no sigui
                            gestionada directament pel nostre web màster. La
                            funció dels links que apareixen en aquesta web és
                            exclusivament la d’informar l’usuari sobre
                            l’existència d’altres fonts susceptibles d’ampliar
                            els continguts que ofereix aquest lloc web. EL
                            PROPIETARI DE LA WEB no pot garantir ni es
                            responsabilitza del funcionament o accessibilitat
                            dels llocs enllaçats, ni suggereix, convida o
                            recomana la visita als mateixos, de manera que
                            tampoc serà responsable del resultat obtingut. EL
                            PROPIETARI DE LA WEB no es responsabilitza de
                            l’establiment d’hipervincles per part de tercers.
                        </Typography>

                        <Typography variant="h3" mt={4} mb={2}>
                            PROCEDIMENT EN CAS DE REALITZACIÓ DE ACTIVITATS DE
                            CARÀCTER IL·LÍCIT
                        </Typography>
                        <Typography>
                            En el cas que qualsevol usuari o un tercer consideri
                            que existeixen fets o circumstàncies que revelin el
                            caràcter il·lícit de la utilització de qualsevol
                            contingut i/o de la realització de qualsevol
                            activitat en les pàgines web incloses o accessibles
                            a través del lloc web, haurà enviar una notificació
                            a EL PROPIETARI DE LA WEB identificant degudament,
                            especificant les suposades infraccions i declarant
                            expressament i sota la seva responsabilitat que la
                            informació proporcionada en la notificació és
                            exacta.
                        </Typography>

                        <Typography variant="h3" mt={4} mb={2}>
                            PUBLICACIONS
                        </Typography>
                        <Typography>
                            La informació administrativa facilitada a través del
                            lloc web no substitueix la publicitat legal de les
                            lleis, normatives, plans, disposicions generals i
                            actes que hagin de ser publicats formalment als
                            diaris oficials de les administracions públiques,
                            que constitueixen l’únic instrument que dóna fe de
                            la seva autenticitat i contingut. La informació
                            disponible en aquest lloc web s’ha d’entendre com
                            una guia sense propòsit de validesa legal.
                        </Typography>

                        <Typography variant="h3" mt={4} mb={2}>
                            PROPIETAT INTEL·LECTUAL I INDUSTRIAL
                        </Typography>
                        <Typography>
                            EL PROPIETARI DE LA WEB per sí mateixa o com
                            cessionària, es titular de tots els drets de
                            propietat intel·lectual i industrial de la seva
                            pàgina web, així com dels elements continguts a la
                            mateixa (a títol informatiu, imatges, so, àudio,
                            vídeo, software o textos; marques o logotipus,
                            combinacions de colors, estructura i disseny,
                            selecció de materials emprats, programes d’ordinador
                            necessaris pel seu funcionament, accés i us, etc.),
                            titularitat de EL PROPIETARI DE LA WEB. Seran, per
                            conseqüent, obres protegides com propietat
                            intel·lectual pel ordenament jurídic espanyol, sent
                            aplicables tant la normativa espanyola i comunitària
                            en aquest camp, como els tractats Internacionals
                            relatius a la matèria i subscrits per Espanya.
                        </Typography>

                        <Typography>
                            Tots els drets reservats. En virtut del que disposen
                            els articles 8 y 32.1, paràgraf segon, de la Llei de
                            Propietat Intel·lectual, queden expressament
                            prohibides la reproducció, la distribució i la
                            comunicació pública, inclosa la seva modalitat de
                            posada a disposició, de la totalitat o part dels
                            continguts d’aquesta pàgina web, amb finalitats
                            comercials, en qualsevol suport i per qualsevol
                            mitja tècnic, sense la autorització de EL PROPIETARI
                            DE LA WEB.
                        </Typography>

                        <Typography>
                            L’usuari se compromet a respectar els drets de
                            Propietat Intel·lectual i Industrial titularitat de
                            EL EL PROPIETARI DE LA WEB. Podrà visualitzar els
                            elements del portal i fins i tot imprimir-los,
                            copiar-los i emmagatzemar-los en el disc duro del
                            seu ordinador o a qualsevol altre suport física
                            sempre i quan sigui, única i exclusivament, pel seu
                            us personal i privat. L’usuari haurà d’abstenir-se
                            de suprimir, alterar, eludir o manipular qualsevol
                            dispositiu de protecció o sistema de seguretat que
                            estigues instal·lat en les pàgines de EL PROPIETARI
                            DE LA WEB.
                        </Typography>

                        <Typography variant="h3" mt={4} mb={2}>
                            ACCIONS LEGALS, LEGISLACIÓ APLICABLE I JURISDICCIÓ
                        </Typography>
                        <Typography>
                            EL PROPIETARI DE LA WEB es reserva, tanmateix, la
                            facultat de presentar les accions civils o penals
                            que consideri oportunes per la utilització indeguda
                            del seu lloc web i els seus continguts, o per
                            l’incompliment de les presents condicions.
                        </Typography>

                        <Typography>
                            La relació entre l’usuari i el prestador es regirà
                            per la normativa vigent i d’aplicació al territori
                            espanyol. De sorgir qualsevol controvèrsia les parts
                            podran sotmetre els seus conflictes a arbitratge o
                            recórrer a la jurisdicció ordinària complint amb les
                            normes sobre jurisdicció i competència al respecte.
                            EL PROPIETARI DE LA WEB te el seu domicili a
                            BARCELONA, Espanya.
                        </Typography>

                        <Typography>
                            La llengua utilitzada serà el català.
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </Page>
    );
};

export default AvisLegal;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
    },
    header: {
        height: 200,
        marginTop: 200,
    },
}));
