import {
    Alert,
    CircularProgress,
    Container,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import act1 from "../../../assets/activitats/a_coneixer.jpg";
import act2 from "../../../assets/activitats/a_passejada.jpg";
import ActivitatList from "./components/ActivitatList";
import bg from "../../../assets/fons/background_clar.jpg";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Sub } from "../../../assets/img/sub.svg";
import { useEffect, useState } from "react";
import { getElements } from "../../../database/API";
import { useTranslation } from "react-i18next";

// const activitats = [
//     {
//         id: 1,
//         nom: "Conèixer les alpaques",
//         image: act1,
//         descripcio: "Una proposta divertida, educativa i màgica.",
//         duracio: "45 min",
//         preu_adult: 16,
//         preu_nen: 13,
//     },
//     {
//         id: 2,
//         nom: "Passeja amb les alpaques",
//         image: act2,
//         descripcio: "Una experiència única que et farà feliç!",
//         duracio: "1 hora i 30 min",
//         preu_adult: 20,
//         preu_4: 18.5,
//     },
// ];

const Activitats = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");
    const [activitats, setActivitats] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const get = async () => {
            const { result } = await getElements("activitatsDes");
            setActivitats(result);
            setLoading(false);
        };
        get();
    }, []);

    return (
        <Box className={classes.main}>
            <Container maxWidth="md">
                <Box
                    display={"flex"}
                    flexDirection={matches ? "row" : "column"}
                    pt={6}
                >
                    <Typography variant="h1" mr={2}>
                        {t("activitats")}
                    </Typography>
                    <Typography variant="h2">{t("destacades")}</Typography>
                </Box>
                <Sub
                    className={classes.sub}
                    style={{ width: matches ? 600 : 250 }}
                />
                <Box mb={3} />
                <Typography variant="body1">{t("activitats-tots")}</Typography>
                <Box mb={4} />

                {loading ? (
                    <Box py={10} display="flex" justifyContent={"center"}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={5}>
                        {activitats?.map((item, index) => (
                            <ActivitatList
                                item={item}
                                key={item.id}
                                index={index}
                            />
                        ))}
                    </Grid>
                )}
                <Alert severity="warning">{t("alerta-activitats")}</Alert>
            </Container>
        </Box>
    );
};

export default Activitats;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        position: "relative",
        paddingTop: 100,
        paddingBottom: 100,
    },
    main_m: {
        height: 700,
        background: `url(${bg})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
    },
    img: {
        width: "100%",
        maxWidth: 400,
    },
    simbol1: {
        width: 120,
        fill: theme.color.secondary,
        position: "absolute",
        left: -10,
        top: 20,
        transform: "rotate(-10deg)",
    },
    simbol2: {
        width: 50,
        fill: theme.color.primary,
        position: "absolute",
        right: "40%",
        top: "32%",
        transform: "scaleX(-1)",
    },
    alpaca: {
        position: "absolute",
        width: 400,
        left: 0,
        bottom: 0,
    },
    sub: {
        fill: theme.color.secondary,
    },
}));
