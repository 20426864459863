import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Input, Menu } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
// import { logout } from "../../database/API";
// import { ReactComponent as Icon } from "../../assets/img/logo-pendol.svg";
import theme from "../../theme";
import bg from "../../assets/fons/background_clar.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiToolbar-root": {
            // boxShadow: '#00000020 1px 3px 20px 1px ',
            // backgroundColor: "white",
            borderBottom: "1px solid #e6e6e7",
            backgroundColor: theme.palette.background.main,
            background: `url(${bg})`,
        },
    },
    white: {
        "& .MuiToolbar-root": {
            backgroundColor: "white",
        },
    },
    color: {
        "& .MuiToolbar-root": {
            backgroundColor: theme.palette.background.main,
        },
    },
    logo: {
        height: "70px !important",
        "& .cls-1": {
            fill: theme.palette.background.color,
        },
    },
}));

const TopBarAdmin = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    let navigate = useNavigate();
    const [user, setUser] = useState();
    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        const u = localStorage.getItem("user");
        setUser(JSON.parse(u));
    }, []);

    const logOut = () => {
        // logout();
        localStorage.removeItem("user");
        localStorage.removeItem("isLoggedIn");
        navigate("/");
    };

    return (
        <AppBar
            className={clsx(
                classes.root,
                matches ? classes.white : classes.color
            )}
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    {/* <Icon
						alt="Logo Pèndol Guies"
						className={classes.logo}
						width={200}
					/> */}
                </RouterLink>

                <Box flexGrow={1} />
                <Hidden>
                    <Hidden mdDown>
                        <Box mr={3}>
                            <Typography
                                variant="h5"
                                style={{ color: "#727272", textAlign: "right" }}
                            >
                                {user?.name}
                            </Typography>
                            <Typography
                                variant="caption"
                                style={{ color: "#979797", textAlign: "right" }}
                            >
                                {user?.email}
                            </Typography>
                        </Box>
                    </Hidden>
                    <IconButton onClick={() => logOut()}>
                        <Input color="secondary" />
                    </IconButton>
                </Hidden>
                <Hidden mdUp>
                    <IconButton
                        color="inherit"
                        onClick={() => onMobileNavOpen()}
                    >
                        <Menu style={{ fill: theme.palette.text.secondary }} />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBarAdmin.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

export default TopBarAdmin;
