import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/fons/background_fost.jpg";
import img from "../../../assets/headers/protagonistes.jpeg";
import Header from "../../../components/layouts/Header";
import { useTranslation } from "react-i18next";

const Espai = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Page title={"Merolla de Berguedà - " + t("espai")}>
            <Header title={t("espai")} img={img} />
            <Box className={classes.main}>
                <Container>
                    <Box textAlign={"center"}>
                        <Typography>{t("construccio")}</Typography>
                    </Box>
                </Container>
            </Box>
        </Page>
    );
};

export default Espai;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
    },
    header: {
        height: 200,
        marginTop: 200,
    },
}));
