import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import theme from "../../theme";

const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "10px 20px",
    borderRadius: 5,
    lineHeight: 1,
    color: "white",
    backgroundColor: "white",
    borderColor: theme.palette.background.main,
    fontFamily: "Raleway",
    "&:hover": {
        backgroundColor: theme.color.primary,
        color: "white",
        borderColor: "transparent",
        boxShadow: "none",
        "& svg": {
            fill: theme.color.primary,
        },
        "& .MuiTypography-root": {
            color: "white",
        },
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "transparent",
        borderColor: "transparent",
    },
    "&:focus": {
        boxShadow: "none",
    },
});

export default function CustomButtonAdmin({ title, danger, ...rest }) {
    const classes = useStyles();
    return (
        <BootstrapButton
            {...rest}
            variant="contained"
            style={{
                paddingRight: 40,
                paddingLeft: 40,
                marginTop: 30,
            }}
        >
            <Typography variant="body1" style={{ zIndex: 1 }}>
                {title}
            </Typography>
        </BootstrapButton>
    );
}

const useStyles = makeStyles((theme) => ({
    img: {
        position: "absolute",
        transition: "0.2s",
        zIndex: 0,
        fill: theme.color.secondary,
    },
}));
