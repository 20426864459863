import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Header from "../../../components/layouts/Header";
import Page from "../../../components/layouts/Page";
import img from "../../../assets/headers/quisom.jpeg";
import img2 from "../../../assets/img/foto-holahola.png";
import contacte from "../../../assets/img/contacte.png";
import bg from "../../../assets/fons/background_fost.jpg";
import bg2 from "../../../assets/fons/background_clar.jpg";
import alpaca from "../../../assets/img/alpaca-cap.png";
import alpaca2 from "../../../assets/img/alpaca-cap-2.png";
import { ReactComponent as Simbol1 } from "../../../assets/simbols/simbol3.svg";
import { useTranslation } from "react-i18next";

const QuiSom = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");
    const { t } = useTranslation();

    return (
        <Page title={t("Merolla de Berguedà - " + t("qui_som_titol"))}>
            <Header title={t("qui_som_titol")} img={img} />
            <Box className={classes.main}>
                <Container maxWidth="md" style={{ zIndex: 1 }}>
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Grid item md={6} position="relative">
                            <Simbol1
                                className={
                                    matches
                                        ? classes.simbol1
                                        : classes.simbol1_m
                                }
                            />
                            <Typography variant="h2" mb={4}>
                                HOLA, HOLA!
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p1")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p2")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p3")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p4")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p5")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p6")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p7")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p8")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p9")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_p10")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} textAlign="left">
                            <img
                                src={img2}
                                className={classes.img}
                                alt="Merolla de Berguedà"
                            />
                        </Grid>
                    </Grid>
                </Container>
                {matches && (
                    <img
                        src={alpaca}
                        alt="Merolla de Berguedà"
                        className={classes.alpaca}
                    />
                )}
            </Box>
            <Box className={classes.main2}>
                <Container maxWidth="md" style={{ zIndex: 1 }}>
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Grid item md={6} position="relative">
                            <Simbol1
                                className={
                                    matches
                                        ? classes.simbol1
                                        : classes.simbol1_m
                                }
                            />
                            <Typography variant="h2" mb={4}>
                                {t("qui_som_historia")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p1")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p2")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p3")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p4")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p5")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p6")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} textAlign="left">
                            <img
                                src={contacte}
                                className={classes.img}
                                alt="Merolla de Berguedà"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Typography variant="body1">
                                {t("qui_som_historia_p7")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p8")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p9")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p10")}
                            </Typography>
                            <Typography variant="body1">
                                {t("qui_som_historia_p11")}
                            </Typography>

                            <Typography variant="body1">
                                {t("qui_som_historia_p12")}
                            </Typography>

                            <Typography variant="body1">
                                {t("qui_som_historia_p13")}
                            </Typography>

                            <Typography variant="body1">
                                {t("qui_som_historia_p14")}
                            </Typography>

                            <Typography variant="body1">
                                {t("qui_som_historia_p15")}
                            </Typography>

                            <Typography variant="body1">
                                {t("qui_som_historia_p16")}
                            </Typography>

                            <Typography variant="body1">
                                {t("qui_som_historia_p17")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                {matches && (
                    <img
                        src={alpaca2}
                        alt="Merolla de Berguedà"
                        className={classes.alpaca2}
                    />
                )}
            </Box>
        </Page>
    );
};

export default QuiSom;

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${bg})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
        position: "relative",
    },
    main_m: {
        height: 700,
        background: `url(${bg})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
    },
    main2: {
        background: `url(${bg2})`,
        display: "flex",
        paddingTop: 200,
        paddingBottom: 200,
        flexDirection: "column",
        position: "relative",
    },
    img: {
        width: "100%",
        maxWidth: 400,
        zIndex: 0,
    },
    simbol1: {
        width: 60,
        fill: theme.color.secondary,
        position: "absolute",
        left: -30,
        top: 20,
        transform: "rotate(-10deg)",
    },
    simbol2: {
        width: 50,
        fill: theme.color.primary,
        position: "absolute",
        right: 90,
        top: "32%",
        transform: "scaleX(-1)",
    },
    simbol1_m: {
        width: 60,
        fill: theme.color.secondary,
        position: "absolute",
        left: 0,
        top: -30,
        transform: "rotate(-10deg)",
    },
    alpaca: {
        position: "absolute",
        width: 400,
        left: 0,
        bottom: 0,
    },

    alpaca2: {
        position: "absolute",
        width: 400,
        right: 0,
        bottom: 0,
    },
}));
