import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import svg from "../../../../assets/simbols/mascara-activitats-1.svg";
import svg2 from "../../../../assets/simbols/mascara-activitats-2.svg";
import CustomButton from "../../../../components/CustomButton";
import { ReactComponent as Simbol1 } from "../../../../assets/simbols/simbol3.svg";
import { ReactComponent as Simbol2 } from "../../../../assets/simbols/simbol2.svg";
import { Add, WhatsApp } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ActivitatList = ({ item, index }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <Grid item md={6} mb={5}>
            <Box zIndex={1} position="relative">
                <Typography variant="h3" className={classes.text}>
                    {removeLastWord(
                        i18n.language === "ca" ? item.nom : item?.nom_es
                    )}
                </Typography>
                <Typography variant="h4" className={classes.text}>
                    {lastWord(i18n.language === "ca" ? item.nom : item?.nom_es)}
                </Typography>
            </Box>
            <Box position={"relative"}>
                <Box zIndex={0} mt={-5} className={classes.imgWrap}>
                    <img
                        src={
                            "https://api.merollabergueda.com/public/storage/" +
                            item.foto
                        }
                        alt={i18n.language === "ca" ? item.nom : item.nom_es}
                        className={index % 2 === 0 ? classes.img : classes.img2}
                    />
                </Box>
                {index % 2 === 0 ? (
                    <Simbol1 className={classes.simbol1} />
                ) : (
                    <Simbol2 className={classes.simbol2} />
                )}
            </Box>
            <Box mt={3}>
                <Typography variant="body1">
                    {i18n.language === "ca"
                        ? item.descripcio
                        : item?.descripcio_es}
                </Typography>
                {item.duracio && (
                    <Typography variant="body1">
                        {t("duracio")} <strong>{item.duracio}</strong>
                    </Typography>
                )}
                {item.preu_adult && (
                    <Typography variant="body1">
                        {t("preu_persona")} <strong>{item.preu_adult} €</strong>
                    </Typography>
                )}
                {item.preu_nen && (
                    <Typography variant="body1">
                        {t("preu_nens")} <strong>{item.preu_nen} €</strong>
                    </Typography>
                )}
                {item.preu_4 && (
                    <Typography variant="body1">
                        {t("preu_4")} <strong>{item.preu_4} €</strong>
                    </Typography>
                )}
                <Box mt={3} />
                <CustomButton
                    onClick={() =>
                        navigate("/activitats/" + item.id, { state: item })
                    }
                    title={
                        <Box display="flex" alignItems="center">
                            <Add />{" "}
                            <span style={{ marginLeft: 5 }}>
                                {t("mes_info")}
                            </span>
                        </Box>
                    }
                />
            </Box>
        </Grid>
    );
};

export default ActivitatList;

const useStyles = makeStyles((theme) => ({
    img: {
        width: "100%",

        height: "100%",
        zIndex: 0,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        objectFit: "cover",
    },
    imgWrap: {
        width: 395,
        height: 390,
        zIndex: 0,
        position: "relative",
        mask: `url(${svg})`,
        maskSize: "cover",
        maskRepeat: "no-repeat",
        maskPosition: "center",
        [theme.breakpoints.down("sm")]: {
            width: 355,
            height: 350,
        },
    },
    img2: {
        height: "100%",
        zIndex: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        objectFit: "cover",
    },
    text: {
        zIndex: 1,
    },
    simbol1: {
        position: "absolute",
        top: 60,
        left: 0,
        width: 60,
        fill: theme.color.secondary,
        transform: "scaleX(-1)",
    },

    simbol2: {
        position: "absolute",
        bottom: 20,
        left: 0,
        width: 60,
        fill: theme.color.primary,
    },
}));

function removeLastWord(str) {
    const lastIndexOfSpace = str?.lastIndexOf(" ");

    if (lastIndexOfSpace === -1) {
        return str;
    }

    return str?.substring(0, lastIndexOfSpace);
}

function lastWord(str) {
    const lastIndexOfSpace = str?.lastIndexOf(" ");

    if (lastIndexOfSpace === -1) {
        return str;
    }

    return str?.substring(lastIndexOfSpace, str.length);
}
