import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    AppBar,
    Box,
    Button,
    Drawer,
    Hidden,
    IconButton,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavBarItem from "./NavBarItem";
import { Close, Facebook, Instagram, Menu } from "@mui/icons-material";
import NavBarItemMobile from "./NavBarItemMobile";
import clsx from "clsx";
import { ReactComponent as Icon } from "../../assets/img/logo_header.svg";
import bg from "../../assets/fons/background_fost.jpg";
import { ReactComponent as MaskTop } from "../../assets/simbols/mascara-top.svg";
import mask from "../../assets/simbols/mascara-top.svg";
import LangSelector from "../../lang/LangSelector";
import { useTranslation } from "react-i18next";
import { ReactComponent as TikTok } from "../../assets/img/tiktok.svg";

const useStyles = makeStyles((theme) => ({
    main: {
        width: "100%",
        zIndex: 100,
        paddingBottom: 20,
        position: "fixed",
    },
    nav: {
        transition: "0.2s",
    },
    navBar: {
        backgroundColor: "transparent !important",
        boxShadow: "none",
    },
    navBarDown: {
        backgroundColor: theme.color.background,
        boxShadow: "none",
        background: `url(${bg})`,
        mask: `url(${mask})`,
        maskSize: "cover",
        maskRepeat: "no-repeat",
        maskPosition: "center",
    },
    logo: {
        fill: theme.color.primary,
    },
    maskTop: {
        // backgroundImage: `url(${bg})`,
        width: "100%",
    },
    iconSVG: {
        width: 22,
        height: 22,
        fontSize: 20,
        transition: "0.2s",
        "&:hover": {
            fill: theme.color.primary,
        },
    },
    icon: {
        transition: "0.2s",
        "&:hover": {
            fill: theme.color.primary,
        },
    },
}));

const TopBarPublic = ({ className, onMobileNavOpen, ...rest }) => {
    const matches = useMediaQuery("(min-width:960px)");
    const matches2 = useMediaQuery("(min-width:2000px)");
    const classes = useStyles();
    const [menu, setMenu] = useState(null);
    const [scrollY, setScrollY] = useState(window.scrollY);
    const { t } = useTranslation();

    const items = [
        {
            title: t("Inici"),
            to: "/",
        },
        {
            title: t("activitats"),
            to: "/activitats",
        },
        {
            title: t("les_protagonistes"),
            to: "/protagonistes",
        },
        {
            title: t("espai"),
            to: "/espai",
        },
        {
            title: t("escoles"),
            to: "/escoles",
        },
        {
            title: t("qui_som_titol"),
            to: "/qui-som",
        },
        {
            title: t("contacta"),
            to: "/contacte",
        },
    ];
    const openMenu = (event) => {
        setMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setMenu(null);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    return (
        <Box className={classes.main}>
            <AppBar elevation={0} className={classes.navBar}>
                <Toolbar
                    className={clsx(
                        classes.nav,
                        // scrollY > 200 ? classes.navBarDown : classes.navBar
                        classes.navBarDown
                    )}
                    style={{
                        paddingBottom: matches2 ? 80 : 60,
                        paddingTop: matches2 ? 30 : 10,
                    }}
                >
                    <a href="/" style={{ marginTop: 10, marginBottom: 10 }}>
                        <Icon
                            alt="Merolla de Berguedà"
                            width={70}
                            className={classes.logo}
                        />
                    </a>
                    <Box flexGrow={1} />

                    <Hidden lgDown>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            {items?.map((item) => (
                                <NavBarItem
                                    to={item.to}
                                    key={item.title}
                                    title={item.title}
                                />
                            ))}
                            <Button
                                focusRipple
                                className={classes.iconWrap}
                                onClick={() =>
                                    window.open(
                                        "https://www.instagram.com/merollabergueda/"
                                    )
                                }
                            >
                                <Instagram className={classes.icon} />
                            </Button>

                            <Button
                                className={classes.iconWrap}
                                onClick={() =>
                                    window.open(
                                        "https://www.facebook.com/merollabergueda"
                                    )
                                }
                            >
                                <Facebook className={classes.icon} />
                            </Button>
                            {/* <Button className={classes.iconWrap} onClick={() => window.open("")}>
                                <Twitter className={classes.icon} />
                            </Button> */}
                            <Button
                                className={classes.iconWrap}
                                onClick={() =>
                                    window.open(
                                        "https://www.tiktok.com/@merollabergueda?lang=es"
                                    )
                                }
                            >
                                <TikTok
                                    className={classes.iconSVG}
                                    fontSize={10}
                                />
                            </Button>
                        </Box>
                    </Hidden>
                    <Hidden lgUp>
                        <IconButton
                            style={{ zIndex: 10 }}
                            color="primary"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={openMenu}
                            className={classes.buttonDrawer}
                        >
                            <Menu color="secondary" />
                        </IconButton>
                        <Drawer open={menu} onClose={closeMenu}>
                            <IconButton>
                                <Close onClick={closeMenu} />
                            </IconButton>
                            {items?.map((item) => (
                                <NavBarItemMobile
                                    to={item.to}
                                    key={item.title}
                                    title={item.title}
                                    closeMenu={closeMenu}
                                />
                            ))}
                            <LangSelector />
                        </Drawer>
                    </Hidden>
                    <Hidden mdDown>
                        <Box flexGrow={1} />
                        <LangSelector />
                    </Hidden>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

TopBarPublic.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;
